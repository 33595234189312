import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: '',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: '8120920c-eef3-4d58-a3ac-d940210346cf',
  footerLogoId: '82af61ac-89c5-4479-9f72-9c4256f0e1d3',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap',
  defaultTitle: 'Cattogames',
  titleTemplate: 'or-site-cattogames | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/consent/8ae73802-3276-419d-85a2-a75f4733f81c/otSDKStub.js',
      strategy: 'afterInteractive',
      'data-domain-script': '8ae73802-3276-419d-85a2-a75f4733f81c'
    },
    {
      id: 'ot-callback-script',
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      strategy: 'afterInteractive',
    },
  ],
  links: [
  ],
};